import { useCallback, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useLocalStorage } from "@uidotdev/usehooks";
import invariant from "tiny-invariant";
import { useAccount, useChainId } from "wagmi";

import { ZkCertListItem, ZkCertStandard } from "shared/snap";
import {
  getZkCertStorageHashesQueryOptions,
  useGetZkCertStorageHashesQuery,
  useInvokeSnapMutation,
} from "shared/snap/rq";
import { GetInvokeSnapResponse } from "shared/snap/types/utils";
import { useSnapClient } from "shared/snap/wagmi";
import { catchError } from "shared/ui/toast";

type CertsStore = Record<ZkCertStandard, ZkCertListItem[]>;

type Cert = {
  expirationDateMS: number;
  standard: ZkCertStandard;
} & ZkCertListItem;

export const useCerts = () => {
  const { address } = useAccount();
  const chainId = useChainId();
  const { client } = useSnapClient();
  const queryClient = useQueryClient();

  const [hashes, setHashes] = useLocalStorage<
    Partial<Record<ZkCertStandard, string>>
  >(`store-hashes-${chainId}`, {});

  const query = useGetZkCertStorageHashesQuery();

  const [certsStore, setCertsStore] = useLocalStorage<Cert[]>(
    `zk-certs-${chainId}`,
    []
  );

  const setCerts = useCallback(
    (newStore: CertsStore, hashes: Partial<Record<ZkCertStandard, string>>) => {
      const newCerts = Object.entries(newStore).reduce<Cert[]>(
        (acc, [standard, items]) => {
          const certs = items.map((item) => {
            return {
              ...item,
              standard,
              expirationDateMS: item.expirationDate * 1000,
            } as Cert;
          });

          acc.push(...certs);
          return acc;
        },
        []
      );
      setHashes(hashes);
      setCertsStore(newCerts);
    },
    [setCertsStore, setHashes]
  );

  const listZkCertsMutation = useInvokeSnapMutation("listZkCerts", {
    onSuccess: async (data) => {
      invariant(address, "address is undefined");
      invariant(client, "client is undefined");

      const queryOptions = getZkCertStorageHashesQueryOptions({
        chainId,
        address,
        client,
      });
      const hashesResponse = await queryClient.fetchQuery(queryOptions);
      queryClient.setQueryData(queryOptions.queryKey, hashesResponse);

      setCerts(data, hashesResponse);

      return;
    },
    onError: catchError,
  });

  const clearMutation = useInvokeSnapMutation("clearStorage");

  const handleUpdateCerts = async () => {
    try {
      if (query.data?.gip69) {
        await clearMutation.mutateAsync();
      }
      await listZkCertsMutation.mutateAsync({});
    } catch (error) {
      catchError(error);
    }
  };

  // console.log("certs: ", certsStore);
  // console.log("storeHashes: ", storeHashes);
  // console.log("snapHashes: ", query.data);

  const hasUpdates = !isCertsStateSynced(query.data, hashes, certsStore);

  return {
    certs: certsStore,
    setCerts,
    updateCerts: handleUpdateCerts,
    hasUpdates,
  } as const;
};

function isCertsStateSynced(
  snapHashes: GetInvokeSnapResponse<"getZkCertStorageHashes"> | undefined,
  storeHashes: Partial<Record<ZkCertStandard, string>>,
  certs: Cert[]
) {
  if (!snapHashes) return true;

  const snapEntries = Object.values(snapHashes);
  const storeEntries = Object.values(storeHashes).filter(Boolean);

  if (certs.length !== storeEntries.length) return false;

  if (snapEntries.length !== storeEntries.length) return false;

  const set = new Set([...snapEntries, ...storeEntries]);

  if (set.size > snapEntries.length) return false;

  if (snapHashes.gip69) return false;

  return true;

  // const hasUpdates = query.isSuccess
  //   ? set.size > entries.length ||
  //     entries.length !== lsEntries.length ||
  //     (entries.length && !certsStore.length) ||
  //     Boolean(query.data.gip69)
  //   : false;
}
